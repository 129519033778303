import React from 'react';
import HeroSectionB from '../../HeroSectionB';
import { homeObjOne } from './Data';

function About() {
  return (
    <>
      <HeroSectionB {...homeObjOne} /> 
    </>
  );
}

export default About;