export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Freedom to engage in film',
    headline: 'WEB3 VIDEO STREAMING CINEMA',
    description:
      'Decentralized platform created by and for filmmakers',
    buttonLabel: 'Play Now',
    imgStart: '',
    img: 'images/svg-14.svg',
    alt: 'Credit Card'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Support independent film production',
    headline: 'Crowdfunding',
    description:
    'Create + support exciting and disruptive projects',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: 'images/svg-13.svg',
    alt: 'Vault'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'EASY SETUP',
    headline: 'NFT Minter + Staking Vault',
    description:
      'Start staking and claiming rewards NOW',
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: 'images/svg-12.svg',
    alt: 'Vault'
  };
  
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'DATA ANALYTICS',
    headline: 'Every transaction is stored on the blockchain',
    description:
      'Ownership and transactionality mints entertainment into valuable assets. Join the web3 film makers revolution.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: 'images/svg-16.svg',
    alt: 'Vault'
  };
  
  