import React from 'react';
import Pricing from '../../Pricing';

function LaunchdApps() {
  return (
    <>
      <Pricing />
      
      
    </>
  );
}

export default LaunchdApps;