export const homeObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'About',
    description:
      'NewFilmTech is an autonomous decentralized organization. Video content is hosted through IPFS, and all apps are built on top of Layer 2 Polygon (MATIC) Blockchain, ensuring low cost transactions and gas fees. The platform is currently in "ALPHA" stage and operating on the "Mumbai” Testnet. Early adopters joining the network will be able to migrate acquired tokens, NFTs and rewards directly to Mainnet once we go live!',
    buttonLabel: 'Play Now',
    imgStart: '',
    img: 'images/svg-15.svg',
    alt: 'Credit Card'
  };
  
  export const homeObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: '',
    description:
      'We are currently in Beta working via the Polygon “Mumbai” Testnet & Mumbai Matic Token. Early adopters joining the network to test functionality at this early stage will be able to migrate NFTs, tokens and NFTR rewards obtained in testnet while receiving extra benefits and rewards when we go live on Mainnet!',
    buttonLabel: 'Learn More',
    imgStart: '',
    img: 'images/svg-16.svg',
    alt: 'Vault'
  };
  
  export const homeObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'EASY SETUP',
    headline: 'Super fast and simple onboarding process',
    description:
      "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
    buttonLabel: 'Start Now',
    imgStart: 'start',
    img: 'images/svg-7.svg',
    alt: 'Vault'
  };
  
  export const homeObjFour = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'DATA ANALYTICS',
    headline: 'Every transaction is stored on our secure cloud database',
    description:
      'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: 'images/svg-8.svg',
    alt: 'Vault'
  };