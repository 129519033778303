import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/HomePage/Home';

import About from './components/pages/About/About';
import LaunchdApps from './components/pages/LaunchdApps/LaunchdApps';
import Footer from './components/pages/Footer/Footer';

function App() {
  return (
    <Router>
    <Navbar />
    <Routes>
       <Route path='/' element={<Home />}/>
       
       <Route path='/about' element={<About />}/>
       <Route path='/launch-dapps' element={<LaunchdApps />}/>
    </Routes> 
    <Footer />  
    </Router>
  );
}

export default App;
